import React, { Component } from 'react';
import { Card, Button, Modal, Form, Row,Spinner } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport} from 'react-bootstrap-table2-toolkit';
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import { Fetcher } from "../../../Helpers/fetcher.js";
import { Notify } from "../../../Helpers/notification.js";
import Dropdown from 'react-bootstrap/Dropdown';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { cloneDeep, isEmpty } from 'lodash';
import Cookies from 'js-cookie';

import "./index.css";
import { Link } from 'react-router-dom';
class ListVoiceCampaign extends Component {
	constructor(props) {
		super(props);
		this.state = {
			campaignlist: [],
			selectedCampaignId: 0,
			isButtonDisabled: [],
			confirmStop: false,
			confirmPause: false,
			confirmResume: false,
			confirmRetry: false,
			confirmEditLocation: false,
			reloading: false,
			allSelected: false,
			retryOptionStates: {},
			apiRetryOptions: [],
			loading: false,
			locationList: [],
			location: [],
			selectAllLocations: false,
			confirmEditCLI: false,
			cliList: [],
			selectedCLI: [],
			campaignIdForCLIEdit: '',
			selectAllCLIs: false,
			DedicatedCliFlag: false,
			sizePerPage: 10,
			activeLocationcampaignId: null,
			activeClicampaignId: null,
			selectedCampaignLocationList: [],
			selectedCampaignCliList: [],
			selectedRows: [],
			moduleList: [],
			ttsFlag : false,
			showTable : false
		}
		//<Route path={`${urlPrefix}/campaign-summary`} ><ListCampaign app={props.app} /></Route>
	}
	componentDidMount() {

		const userId = this.props.app.userId;
		
      
		// Get User Module List
		Fetcher.get(this.props.app.apiPath + '/api/obd/module/list/' + userId)
			.then(res => {
				this.setState({
					moduleList: res
				})
				if (!isEmpty(this.state.moduleList) && (!('error' in this.state.moduleList))) {
					this.state.moduleList.map((fileObj) => {
						 if(fileObj.moduleName === 'TTS'){
                            this.state.ttsFlag = true;
							console.log("TTS Flag :"+this.state.ttsFlag);
						}
					})
				}
			})
			.catch(err => { console.log('Error in fetching Modules', err) });


		const timer = setTimeout(() => {
                 this.setState({
					showTable : true
				 })
        }, 2000);

		this.getCampaigns();
		const savedSizePerPage = Cookies.get('voiceCampSumm_S_P_P');
		if (savedSizePerPage) {
			this.setState({ sizePerPage: Number(savedSizePerPage) });
		}
	}



	getCampaigns = () => {
		const userId = this.props.app.userId;
		this.getDedicatedCli(userId,this.props.app.apiPath);
		Fetcher.get( this.props.app.apiPath + '/api/obd/campaign/' + userId)
			.then(res => {
				this.setState({
					campaignlist: res,
					confirmStop: false,
					confirmPause: false,
					confirmResume: false,
					confirmRetry: false,
					confirmEditLocation: false
				})
			})
			.catch(err => { console.log('Error in fetching Campaigns', err) });

			Fetcher.get(this.props.app.apiPath + '/api/obd/module/list/' + userId)
			.then(res => {
				if (!isEmpty(res) && (!('error' in res))) {
					res.map((fileObj) => {
						if (fileObj.moduleName === 'Dedicated Cli') {
							this.state.DedicatedCliFlag = true;
						}
					})
				}
			})
			.catch(err => { console.log('Error in fetching Modules', err) });
	}

	getDedicatedCli = (userId, apiPath) => {
		Fetcher.get(apiPath + '/api/obd/dedicated/cli/allocated/' + userId)
			.then(res => {
				this.setState({
					cliList: res
				})
			})
			.catch(err => {
				console.log('Error in fetching dedicated cli', err);
			});
	}

	refreshPage = () => {
		this.setState({ reloading: true });
		//window.location.reload();

		setTimeout(() => {
			window.location.reload(false);
		}, 500);
	}

	bulkGenerateReport = () => {
		
             console.log("select ids : "+ this.state.selectedRows);

			 const idsToFind = this.state.selectedRows;

			 const filteredRecords = this.state.campaignlist.filter(record => idsToFind.includes(record.campaignId));

			 const totalCallSum = filteredRecords.reduce((sum, record) => sum + record.numbersUploaded, 0);

			 console.log("Total Calls : "+totalCallSum);

			 if(totalCallSum>1000000){

				Notify.notify('Total Numbers Limit cannot exceed 10 Lakhs.');
				this.setState({ reloading: true });

		       setTimeout(() => {
			       window.location.reload(false);
		       }, 2500);
			   return;
			 }

			 const data = {
			     "campaignId":0,
                 "reportType":"bulk",
                 "campaignIds":this.state.selectedRows.toString()
		     };

             console.log("JSON : "+JSON.stringify(data));

			 return Fetcher.post(this.props.app.apiPath+ '/api/obd/report/generate', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				Notify.notify(res.message);
				this.setState({ reloading: true });

		       setTimeout(() => {
			       window.location.reload(false);
		       }, 2500);
			})
			.catch(err => { console.log('Error in Generating Report', err) });


	}


	downloadReport = (index, campaignId, type) => {
		const data = {
			"campaignId": campaignId,
			"reprtType": type
		};
		return Fetcher.post(this.props.app.apiPath + '/api/obd/report/download', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				alert(res.message);
				//document.getElementById('report-' + index).disabled = false;
				//document.getElementById('report-' + index).innerHTML = "Download";
				window.open(this.props.app.reportPath + '/reports/' + campaignId + '.zip', 'Download');
			})
			.catch(err => { console.log('Error in Downloading Report', err) });
	}

	downloadPending = (index, campaignId) => {
		const data = {
			"campaignId": campaignId,
			"reportType": 'pending_data'
		};
		return Fetcher.post(this.props.app.apiPath + '/api/obd/report/pending', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				//let csvRows = res.join('\n');
				let csvRows = res.map(item => item.substring(0, 10)).join('\n');
				// Create a Blob and initiate the download
				const blob = new Blob([csvRows], { type: 'text/csv' });
				const link = document.createElement('a');
				link.href = window.URL.createObjectURL(blob);
				link.download = 'pending_data_'+campaignId+'.csv';
				link.click();
			})
		.catch(err => { 
			Notify.notify('Something went wrong');
			console.log('Error in Downloading Report', err);
		});
	}

	generateReport = (index, campaignId, reportType) => {
		const data = {
			"campaignId": campaignId,
			"reportType": reportType
		};
		return Fetcher.post(this.props.app.apiPath + '/api/obd/report/generate', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {

				alert(res.message);
				this.getCampaigns();
				
				//document.getElementById("greport-" + index).disabled = true;
				//document.getElementById('greport-' + index).innerHTML = "Preparing";
				
			})
			.catch(err => { console.log('Error in Generating Report', err) });
	}

	confirmStop = (status, campaignId) => {
		this.setState({
			confirmStop: status,
			selectedCampaignId: campaignId
		});
	}

	confirmPause = (status, campaignId) => {
		this.setState({
			confirmPause: status,
			selectedCampaignId: campaignId
		});
	}

	confirmResume = (status, campaignId) => {
		this.setState({
			confirmResume: status,
			selectedCampaignId: campaignId
		});
	}

	confirmRetry = (status, campaignId) => {
		if(this.state.loading) {
			return;
		}
		this.setState({
			confirmRetry: status,
			selectedCampaignId: campaignId
		});
		if (status) {
			Fetcher.get(this.props.app.apiPath + '/api/obd/campaign/retry/options/' + campaignId)
			.then(res => {
				const retryOptionStates = {};
                res.forEach(option => {
                    retryOptionStates[option.optionName] = false;
                });
                this.setState({
                    apiRetryOptions: res,
					retryOptionStates: retryOptionStates 
                });
            })
            .catch(err => {
                console.log('Error in fetching retry options', err);
            });
		}
	}

	confirmEditLocation = (status, campaignId) => {
		if (this.state.loading) {
			return;
		}
		this.setState({
			confirmEditLocation: status,
			selectedCampaignId: campaignId
		});
	
		if (status) {
			const userLocationsUrl = `${this.props.app.apiPath}/api/obd/user/locations/${this.props.app.userId}`;
			const campaignLocationsUrl = `${this.props.app.apiPath}/api/obd/campaign/location/list`;
	
			const fetchUserLocations = Fetcher.get(userLocationsUrl);
			const fetchCampaignLocations = Fetcher.post(campaignLocationsUrl, {
				headers: { "Content-type": "application/json" },
				method: 'POST',
				body: JSON.stringify({ campaignId })
			});
	
			Promise.all([fetchUserLocations, fetchCampaignLocations])
				.then(([availableLocations, selectedLocations]) => {
					const selectedLocationIds = selectedLocations.map(loc => loc.locationId);
					const selectedLocationsDetails = availableLocations.filter(loc => selectedLocationIds.includes(loc.locationId));
	
					this.setState({
						locationList: availableLocations,
						location: selectedLocationsDetails
					});
				})
				.catch(err => {
					console.log('Error in fetching locations', err);
					this.setState({ loading: false });
				});
		}
	}
	

	handleLocationSelect = (locationId) => {
		const { location } = this.state;
		const updatedLocations = location.some(loc => loc.locationId === locationId)
		  ? location.filter(loc => loc.locationId !== locationId)
		  : [...location, { locationId, locationName: this.getLocationNameById(locationId) }];
		
		this.setState({ location: updatedLocations, selectAllLocations: false });
	}

	handleSelectAllLocations = () => {
		const { selectAllLocations, locationList } = this.state;
		if (!selectAllLocations) {
		  const allLocations = locationList.map(loc => ({
			locationId: loc.locationId,
			locationName: loc.locationName
		  }));
		  this.setState({ location: allLocations, selectAllLocations: true });
		} else {
		  this.setState({ location: [], selectAllLocations: false });
		}
	}

	getLocationNameById = (locationId) => {
		const location = this.state.locationList.find(loc => loc.locationId === locationId);
		return location ? location.locationName : '';
	}

	stopCampaign = () => {
		const campaignId = this.state.selectedCampaignId;
		const data = {
			campaignId: campaignId
		}

		return Fetcher.post(this.props.app.apiPath + '/api/obd/campaign/stop', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				Notify.notify(res.message);
				this.getCampaigns();
			})
			.catch(err => {
				console.log('Error in Stopping', err);
			});
	}

	pauseCampaign = () => {
		const campaignId = this.state.selectedCampaignId;
		const data = {
			campaignId: campaignId
		}

		return Fetcher.post(this.props.app.apiPath + '/api/obd/campaign/pause', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				Notify.notify(res.message);
				this.getCampaigns();
			})
			.catch(err => {
				console.log('Error in Pause', err);
			});
	}
	
	resumeCampaign = () => {
		const campaignId = this.state.selectedCampaignId;
		const data = {
			campaignId: campaignId,
			userId: this.props.app.userId
		}

		return Fetcher.post(this.props.app.apiPath + '/api/obd/campaign/resume', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				Notify.notify(res.message);
				this.getCampaigns();
			})
			.catch(err => {
				console.log('Error in Resume', err);
			});
	}

	retryCampaign = () => {
		this.setState({ loading: true });
		const userId = this.props.app.userId;
		const campaignId = this.state.selectedCampaignId;
		const { retryOptionStates, allSelected } = this.state;
		let selectedOptions;
		if (allSelected) {
			selectedOptions = ["All"];
		} else {
			selectedOptions = Object.keys(retryOptionStates).filter((option) => retryOptionStates[option]);
		}

		if (selectedOptions.length === 0) {
			this.setState({ loading: false });
			alert("Please select at least one option.");
			return;
		}
		const selectedOptionsString = selectedOptions.join(':');
		const data = {
			campaignId: campaignId,
			userId: userId,
			selectedOptions: selectedOptionsString
		}

		return Fetcher.post(this.props.app.apiPath + '/api/obd/campaign/retry', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				Notify.notify(res.message);
				this.getCampaigns();
				// Reset all option states to false
				const updatedOptionStates = {};
				Object.keys(retryOptionStates).forEach(option => {
					updatedOptionStates[option] = false;
				});
				this.setState({ 
					retryOptionStates: updatedOptionStates,
					allSelected: false,
					loading: false
				});
			})
			.catch(err => {
				console.log('Error in Retry', err);
				this.setState({ loading: false  });
			});
	}

	editLocation = () => {
		this.setState({ loading: true });
		const userId = this.props.app.userId;
		const campaignId = this.state.selectedCampaignId;
		if (this.state.location.length === 0) {
			this.setState({ loading: false });
			Notify.notify('Please select a location');
			return;
		}
		const data = {
			campaignId: campaignId.toString(),
			"location": JSON.stringify({ locationList: this.state.location }),
		}
		return Fetcher.post(this.props.app.apiPath + '/api/obd/edit/campaign/location/', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				Notify.notify(res.message);
				this.getCampaigns();
				this.setState({ 
					locationList: [],
					location: [],
					selectAllLocations: false,
					loading: false
				});
			})
			.catch(err => {
				console.log('Error in edit location', err);
				this.setState({ loading: false  });
			});
	}

	toggleCheckedChange = (e, optionName) => {
        const { checked } = e.target;
        if (optionName === 'All') {
            const updatedOptionStates = {};
            Object.keys(this.state.retryOptionStates).forEach((key) => {
                updatedOptionStates[key] = checked;
            });
            this.setState({
                allSelected: checked,
                retryOptionStates: updatedOptionStates,
            });
        } else {
            this.setState((prevState) => ({
                retryOptionStates: {
                    ...prevState.retryOptionStates,
                    [optionName]: checked,
                },
            }));
        }
    };

	fetchSelectedCLIs  = (campaignId) => {
		// const campaignId = this.state.selectedCampaignId;
		const data = {
			campaignId: campaignId
		}

		return Fetcher.post(this.props.app.apiPath + '/api/obd/campaign/cli/list', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				if (res.error || !Array.isArray(res)) {
					this.setState({
						selectedCLI: [],
						campaignIdForCLIEdit: campaignId
					});
				} else {
					// Filter out CLIs not in cliList and adjust structure
					const filteredCLIs = res.filter(cli => this.state.cliList.some(cl => cl.cliId === cli.cliId))
										   .map(cli => ({ cliId: cli.cliId, cli: cli.cli }));
					
					this.setState({
						selectedCLI: filteredCLIs,
						campaignIdForCLIEdit: campaignId
					});
				}
			})
			.catch(err => {
				console.log('Error in fetching selected CLIs', err);
			});
	}

	confirmEditCLI = (show, campaignId = '') => {
		if (show && campaignId) {
			this.fetchSelectedCLIs(campaignId);
		}
		this.setState({ confirmEditCLI: show, selectAllCLIs: false });
	}
	
	handleSelectAllCLIs = () => {
		const { selectAllCLIs, cliList } = this.state;
		if (!selectAllCLIs) {
		  const allClis = cliList.map(cli => ({
			cliId: cli.cliId,
			cli: cli.cli
		  }));
		  this.setState({ selectedCLI: allClis, selectAllCLIs: true });
		} else {
		  this.setState({ selectedCLI: [], selectAllCLIs: false });
		}
	}
	
	handleCLISelect = (cliId) => {
		const { selectedCLI } = this.state;
		const cliIndex = selectedCLI.findIndex(cli => cli.cliId === cliId);
	
		if (cliIndex > -1) {
			// CLI already selected, remove it
			selectedCLI.splice(cliIndex, 1);
		} else {
			// CLI not selected, add it
			const cli = this.state.cliList.find(cli => cli.cliId === cliId);
			selectedCLI.push({ cliId: cliId, cli: cli ? cli.cli : '' });
		}
	
		this.setState({ selectedCLI, selectAllCLIs: false });
	}
	

	getCliById = (cliId) => {
		const cli  = this.state.cliList.find(cli => cli.cliId === cliId);
		return cli  ? cli.cli : '';
	}
	
	editCLI = () => {
		this.setState({ loading: true });
		const { campaignIdForCLIEdit, selectedCLI } = this.state;
		if(selectedCLI.length===0){
			this.setState({ loading: false });
			Notify.notify('Please select a CLI');
			return;
		}
	
		const data = {
			campaignId: campaignIdForCLIEdit,
			cli: JSON.stringify({ cliList: selectedCLI })
		}
		
		return Fetcher.post(this.props.app.apiPath + '/api/obd/edit/campaign/cli/', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				this.setState({
					selectedCLI: [],
					loading: false 
				});
				Notify.notify(res.message);
				this.confirmEditCLI(false);
            	this.getCampaigns();
			})
			.catch(err => {
				console.log('Error editing CLIs', err);
				this.setState({ loading: false  });
			});
	}
	
	handleSizePerPageChange = (newSizePerPage) => {
		this.setState({ sizePerPage: newSizePerPage });
		Cookies.set('voiceCampSumm_S_P_P', newSizePerPage);
	}

	getCampaignAdditionalInfo = (campaignId, type) => {
		if(this.state.loading === true){
			return;
		}
		this.setState({
			...(type === 'location' && { activeLocationcampaignId: campaignId }),
			...(type === 'cli' && { activeClicampaignId: campaignId }),
			loading: true
		})
		const apiPath = this.props.app.apiPath;
		Fetcher.get(apiPath + '/api/obd/campaign/info/' + campaignId)
			.then(res => {
				this.setState({
					selectedCampaignLocationList: res.locationList,
					selectedCampaignCliList: res.cliList,
					loading: false
				})
			})
			.catch(err => { 
				console.log('Error in fetching Location list', err) 
				this.setState({
					loading: false
				})
			});
	}

	handleDropdownToggle = (isOpen, type) => {
		if (!isOpen) {
			if (type === 'location') {
				this.setState({ activeLocationcampaignId: null });
			} else if (type === 'cli') {
				this.setState({ activeClicampaignId: null });
			}
		}
	};


	handleSelectAll = (isSelect) => {
         const { campaignlist } = this.state;
         if (isSelect) {
              this.setState({ selectedRows: campaignlist.map(p => p.campaignId) });
        } else {
            this.setState({ selectedRows: [] });
        }
    };

	handleSelectRow = (row, isSelect) => {
        this.setState(prevState => {
               const selectedRows = [...prevState.selectedRows];
               if (isSelect) {
                            selectedRows.push(row.campaignId);
               } else {
                        const index = selectedRows.indexOf(row.campaignId);
                        if (index > -1) {
                                    selectedRows.splice(index, 1);
                        }
                }
        return { selectedRows };
       });
    };

	isSelectedAll = () => {
       const { campaignlist, selectedRows } = this.state;
       return selectedRows.length === campaignlist.length;
    };

	


	render() {
         
		const { campaignlist,selectedRows } = this.state;
		const campaigns = this.state.campaignlist;
        let columns = [];
		console.log("tttttt >>>>> "+this.state.ttsFlag);
		if(this.state.ttsFlag === true){
		    columns = [

			{
			     dataField: 'select',
                 text: 'Select',
                 headerRenderer: (columnProps, colIndex, expandColumnProps) => (
                <input
                      type="checkbox"
                      checked={this.isSelectedAll()}
                    onChange={(e) => this.handleSelectAll(e.target.checked)}
					 style={{ margin: '0 auto', display: 'block' }}
                />
                ),
                formatter: (cell, row) => (
                  <input type="checkbox" checked={selectedRows.includes(row.campaignId)}
                    onChange={(e) => this.handleSelectRow(row, e.target.checked)} />
                ),
                headerStyle: { width: '50px' , textAlign: 'center'},
            },
			{
				dataField: 'id',
				text: 'Serial No.',
				sort: false,
				hidden: true,
				csvExport: false
			},
			{
				dataField: 'campaignId',
				text: 'Campaign ID',
				sort: false,
				hidden: false,
				csvExport: true
			},
			{
				dataField: 'campaignName',
				text: 'Campaign Name',
				sort: true,
				searchable: true,
				csvExport: false
			}, {
				dataField: 'name',
				text: 'Campaign Name',
				sort: true,
				searchable: true,
				hidden: true,
				csvExport: true
			}, {
				dataField: 'campaignstatus',
				text: 'Campaign Status',
				sort: true,
				searchable: true
			}, {
				dataField: 'templateId',
				text: 'Campaign Type',
				sort: true,
				searchable: true
			},{
				dataField: 'locationList',
				text: 'Location',
				sort: true,
				searchable: false
			},{
				dataField: 'cliList',
				text: 'CLI',
				sort: true,
				searchable: false
			},{
				dataField: 'scheduleTime',
				text: 'Start Time',
				sort: true,
				searchable: false
			},{
				dataField: 'endTime',
				text: 'End Time',
				sort: true,
				searchable: false
			}, {
				dataField: 'numbersUploaded',
				text: 'Total Numbers',
				sort: false,
				searchable: false
			}, {
				dataField: 'numbersProcessed',
				text: 'Calls Dialed',
				sort: false,
				searchable: false
			}, {
				dataField: 'callsSubmitted',
				text: 'Pending Calls',
				sort: false,
				searchable: false
			},{
				dataField: 'callsSubmittedCsv',
				text: 'Pending Calls',
				sort: false,
				searchable: false,
				hidden: true,
				csvExport: true
			},{
				dataField: 'callsConnected',
				text: 'Connected Calls',
				sort: false,
				searchable: false,
				csvExport: false
			},{
				dataField: 'callsConnectedCsv',
				text: 'Connected Calls',
				sort: false,
				searchable: false,
				hidden: true,
				csvExport: true
			},{
				dataField: 'pulses',
				text: 'Total Pulses',
				sort: true,
				searchable: false
			},{
				dataField: 'dndCount',
				text: 'DnD Count',
				sort: true,
				searchable: false
			},{
				dataField: 'dtmfCount',
				text: 'Dtmf Count',
				sort: true,
				searchable: false,
				csvExport: false
			},{
				dataField: 'dtmf1Count',
				text: 'Dtmf1 Count',
				sort: true,
				searchable: false,
				csvExport: false
			},{
				dataField: 'dtmf2Count',
				text: 'Dtmf2 Count',
				sort: true,
				searchable: false,
				csvExport: false
			},{
				dataField: 'dtmfCountCsv',
				text: 'Dtmf Count',
				sort: true,
				searchable: false,
				hidden: true,
				csvExport: true
			},{
				dataField: 'dtmf1CountCsv',
				text: 'Dtmf1 Count',
				sort: true,
				searchable: false,
				hidden: true,
				csvExport: true
			},{
				dataField: 'dtmf2CountCsv',
				text: 'Dtmf2 Count',
				sort: true,
				searchable: false,
				hidden: true,
				csvExport: true
			},{
				dataField: 'smsCount',
				text: 'SMS Count',
				sort: true,
				searchable: false,
				csvExport : false
			},
			{
				dataField: 'smsCount1',
				text: 'SMS Count',
				sort: true,
				searchable: false,
				hidden : true,
				csvExport : true
			},
			{
				dataField: 'retryCount',
				text: 'Retry Count',
				sort: true,
				searchable: false
			},{
				dataField: 'retries',
				text: 'Is Retry',
				sort: true,
				searchable: false,
				hidden: true
			},
			{
				dataField: 'ttsCount',
				text: 'Character Count',
				sort: false,
				hidden: false,
				csvExport: false
			},
			{
				dataField: 'retries',
				text: 'No. Of retries',
				sort: true,
				searchable: false
			},{
				dataField: 'fullReportStatus',
				text: 'Action',
				sort: false,
				searchable: false,
				csvExport: false
			}, {
				dataField: 'retry',
				text: 'Retry',
				sort: false,
				searchable: false,
				csvExport: false
			}];
		}
		else{
			 columns = [

			{
			     dataField: 'select',
                 text: 'Select',
                 headerRenderer: (columnProps, colIndex, expandColumnProps) => (
                <input
                      type="checkbox"
                      checked={this.isSelectedAll()}
                    onChange={(e) => this.handleSelectAll(e.target.checked)}
					 style={{ margin: '0 auto', display: 'block' }}
                />
                ),
                formatter: (cell, row) => (
                  <input type="checkbox" checked={selectedRows.includes(row.campaignId)}
                    onChange={(e) => this.handleSelectRow(row, e.target.checked)} />
                ),
                headerStyle: { width: '50px' , textAlign: 'center'},
            },
			{
				dataField: 'id',
				text: 'Serial No.',
				sort: false,
				hidden: true,
				csvExport: false
			},
			{
				dataField: 'campaignId',
				text: 'Campaign ID',
				sort: false,
				hidden: false,
				csvExport: true
			},
			{
				dataField: 'campaignName',
				text: 'Campaign Name',
				sort: true,
				searchable: true,
				csvExport: false
			}, {
				dataField: 'name',
				text: 'Campaign Name',
				sort: true,
				searchable: true,
				hidden: true,
				csvExport: true
			}, {
				dataField: 'campaignstatus',
				text: 'Campaign Status',
				sort: true,
				searchable: true
			}, {
				dataField: 'templateId',
				text: 'Campaign Type',
				sort: true,
				searchable: true
			},{
				dataField: 'locationList',
				text: 'Location',
				sort: true,
				searchable: false
			},{
				dataField: 'cliList',
				text: 'CLI',
				sort: true,
				searchable: false
			},{
				dataField: 'scheduleTime',
				text: 'Start Time',
				sort: true,
				searchable: false
			},{
				dataField: 'endTime',
				text: 'End Time',
				sort: true,
				searchable: false
			}, {
				dataField: 'numbersUploaded',
				text: 'Total Numbers',
				sort: false,
				searchable: false
			}, {
				dataField: 'numbersProcessed',
				text: 'Calls Dialed',
				sort: false,
				searchable: false
			}, {
				dataField: 'callsSubmitted',
				text: 'Pending Calls',
				sort: false,
				searchable: false
			},{
				dataField: 'callsSubmittedCsv',
				text: 'Pending Calls',
				sort: false,
				searchable: false,
				hidden: true,
				csvExport: true
			},{
				dataField: 'callsConnected',
				text: 'Connected Calls',
				sort: false,
				searchable: false,
				csvExport: false
			},{
				dataField: 'callsConnectedCsv',
				text: 'Connected Calls',
				sort: false,
				searchable: false,
				hidden: true,
				csvExport: true
			},{
				dataField: 'pulses',
				text: 'Total Pulses',
				sort: true,
				searchable: false
			},{
				dataField: 'dndCount',
				text: 'DnD Count',
				sort: true,
				searchable: false
			},{
				dataField: 'dtmfCount',
				text: 'Dtmf Count',
				sort: true,
				searchable: false,
				csvExport: false
			},{
				dataField: 'dtmf1Count',
				text: 'Dtmf1 Count',
				sort: true,
				searchable: false,
				csvExport: false
			},{
				dataField: 'dtmf2Count',
				text: 'Dtmf2 Count',
				sort: true,
				searchable: false,
				csvExport: false
			},{
				dataField: 'dtmfCountCsv',
				text: 'Dtmf Count',
				sort: true,
				searchable: false,
				hidden: true,
				csvExport: true
			},{
				dataField: 'dtmf1CountCsv',
				text: 'Dtmf1 Count',
				sort: true,
				searchable: false,
				hidden: true,
				csvExport: true
			},{
				dataField: 'dtmf2CountCsv',
				text: 'Dtmf2 Count',
				sort: true,
				searchable: false,
				hidden: true,
				csvExport: true
			},{
				dataField: 'smsCount',
				text: 'SMS Count',
				sort: true,
				searchable: false,
				csvExport: false
			},
			{
				dataField: 'smsCount1',
				text: 'SMS Count',
				sort: true,
				searchable: false,
				hidden : true,
				csvExport : true
			},
			{
				dataField: 'retryCount',
				text: 'Retry Count',
				sort: true,
				searchable: false
			},{
				dataField: 'retries',
				text: 'Is Retry',
				sort: true,
				searchable: false,
				hidden: true
			},
			{
				dataField: 'ttsCount',
				text: 'Character Count',
				sort: false,
				hidden: true,
				csvExport: false
			},
			{
				dataField: 'retries',
				text: 'No. Of retries',
				sort: true,
				searchable: false
			},{
				dataField: 'fullReportStatus',
				text: 'Action',
				sort: false,
				searchable: false,
				csvExport: false
			}, {
				dataField: 'retry',
				text: 'Retry',
				sort: false,
				searchable: false,
				csvExport: false
			}];
		}

		const products = Array.isArray(campaigns) ? campaigns.map((campaign, index) => {
			let template = '';

			switch (campaign.templateId) {
				case 0:
					template = 'Simple IVR';
					break;
				case 1:
					template = 'DTMF';
					break;
				case 2:
					template = 'Call Patch';
					break;
				case 3:
					template = 'Custom IVR';
					break;
				case 7:
					template = 'TTS Simple IVR';
					break;
				case 8:
					template = 'TTS DTMF';
					break;
				case 9:
					template = 'TTS Call Patch';
					break;
				default:
					template = '';
					break;
			}
			let status = '';

			switch (campaign.campaignStatus) {
				case 0:
					status = 'Ready';
					break;
				case 21:
					status = 'Processing';
					break;
				case 1:
					status = 'Running';
					break;
				case 2:
					status = 'Complete';
					break;
				case 3:
					status = 'Low Balance';
					break;
				case 4:
					status = 'Error';
					break;
				case 5:
					status = 'Error';
					break;
				case 6:
					status = 'Cancelling';
					break;
				case 7:
					status = 'Cancelled';
					break;
				case 8:
					status = 'Pausing';
					break;
				case 9:
					status = 'Paused';
					break;
				case 10:
					status = 'Resuming';
					break;
				case 22:
					status = 'Waiting For Channels';
						break;
				default:
					status = '';
					break;
			}
			
			let action = [];
			let retry = [];
			//let 
			action.push(<Dropdown id="dd">
				<Dropdown.Toggle className='btn-sm'>Take Action</Dropdown.Toggle>
					<Dropdown.Menu>
						<Dropdown.Item key={`clone-campaign-${index}`} href={`${this.props.app.urlPrefix}/clone-campaign/${campaign.campaignId}`}>Clone Campaign</Dropdown.Item>
						{(campaign.campaignStatus === 1) && <Dropdown.Item key={`ps-campaign-${index}`} onClick={() => this.confirmPause(true, campaign.campaignId)} href="#">Pause Campaign</Dropdown.Item>}
						{(campaign.campaignStatus === 3 || campaign.campaignStatus === 9 || campaign.campaignStatus === 22) && <Dropdown.Item key={`rs-campaign-${index}`} onClick={() => this.confirmResume(true, campaign.campaignId)} href="#">Resume Campaign</Dropdown.Item>}
						{(campaign.campaignStatus === 1) && <Dropdown.Item key={`st-campaign-${index}`} onClick={() => this.confirmStop(true, campaign.campaignId)} href="#">Stop Campaign</Dropdown.Item>}
						{(campaign.fullReportStatus === '2' || campaign.fullReportStatus === undefined) && <Dropdown.Item id={`greport-${index}`} key={`greport-${index}`} onClick={() => this.generateReport(index, campaign.campaignId, 'full')} href="#">Generate Full Report</Dropdown.Item>}
						{(campaign.campaignStatus === 9) && <Dropdown.Item key={`edit-location-${index}`} onClick={(e) => { this.confirmEditLocation(true, campaign.campaignId) }} href="#">Edit Location</Dropdown.Item>}
						{((campaign.campaignStatus === 9) && this.state.DedicatedCliFlag === true) && <Dropdown.Item key={`edit-cli-${index}`} onClick={() => this.confirmEditCLI(true, campaign.campaignId)} href="#">Edit CLI</Dropdown.Item>}
					</Dropdown.Menu>
				  </Dropdown>);
				if(campaign.campaignStatus === 2 && campaign.isRetry === 0)
				{
					retry.push(<Link data-tip="Retry" key={`${index}-retry`} className="btn btn-sm btn-primary" disabled={this.state.loading} onClick={(e) => { this.confirmRetry(true, campaign.campaignId) }} href="#">Retry</Link>);
				}
				let callsSubmitted=0;
				let dtmfDownload=[];
				dtmfDownload.push(campaign.dtmfCount);
				dtmfDownload.push(' ');
				if(campaign.templateId === 1 || campaign.templateId === 2 || campaign.templateId === 3)
				{
					if(campaign.dtmfReportStatus === '2' || campaign.dtmfReportStatus === undefined)
					{
						dtmfDownload.push( <a data-tip="" key={`${index}-dtmf`} className="download-report" onClick={( e ) => { this.generateReport( true, campaign.campaignId, 'dtmf' ) }} href="#"><i className="fa fa-download"></i></a>);
					}
				}
				let dtmf1Download=[];
				dtmf1Download.push(campaign.dtmf1Count);
				dtmf1Download.push(' ');
				if(campaign.templateId === 3)
				{
					if(campaign.dtmfReportStatus === '2' || campaign.dtmfReportStatus === undefined)
					{
						dtmf1Download.push( <a data-tip="" key={`${index}-dtmf1`} className="download-report" onClick={( e ) => { this.generateReport( true, campaign.campaignId, 'dtmf1' ) }} href="#"><i className="fa fa-download"></i></a>);
					}
				}
				let dtmf2Download=[];
				dtmf2Download.push(campaign.dtmf2Count);
				dtmf2Download.push(' ');
				if(campaign.templateId === 3)
				{
					if(campaign.dtmfReportStatus === '2' || campaign.dtmfReportStatus === undefined)
					{
						dtmf2Download.push( <a data-tip="" key={`${index}-dtmf2`} className="download-report" onClick={( e ) => { this.generateReport( true, campaign.campaignId, 'dtmf2' ) }} href="#"><i className="fa fa-download"></i></a>);
					}
				}

				let ccDownload=[];
				ccDownload.push(campaign.callsConnected);
				ccDownload.push(' ');
				if(campaign.callsConnected > 0)
				{
					if(campaign.ccReportStatus === '2' || campaign.ccReportStatus === undefined)
					{
						ccDownload.push( <a data-tip="" key={`${index}`} className="download-report" onClick={( e ) => { this.generateReport( true, campaign.campaignId, 'connected_calls' ) }} href="#"><i className="fa fa-download"></i></a>);
					}
				}
				let ccPending=[];
				ccPending.push(campaign.numbersUploaded - campaign.dndCount - campaign.numbersProcessed);
				ccDownload.push(' ');
				ccPending.push( <a data-tip="" key={`${index}-pending`} className="download-report" onClick={( e ) => { this.downloadPending( true, campaign.campaignId ) }} href="#"><i className="fa fa-download"></i></a>);
				
				let smsDownload = [];
				smsDownload.push(campaign.smsCount);
				smsDownload.push(' ');
				if(campaign.smsCount > 0){
					if(campaign.smsStatus == 2 || campaign.smsStatus == undefined){
						smsDownload.push( <a data-tip="" key={`${index}-sms`} className="download-report" onClick={( e ) => { this.generateReport( true, campaign.campaignId, 'sms' ) }} href="#"><i className="fa fa-download"></i></a>);
					}
				}

				let selectedLocationAction;
				if(this.state.activeLocationcampaignId !== campaign.campaignId){
					selectedLocationAction = (
						<Dropdown show={false}>
							<Dropdown.Toggle onClick={() => this.getCampaignAdditionalInfo(campaign.campaignId, 'location')} size="sm" variant="success">Location</Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item>
									Location
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					);
				} else {
					selectedLocationAction = (
						<Dropdown show={this.state.activeLocationcampaignId === campaign.campaignId} onToggle={(isOpen) => this.handleDropdownToggle(isOpen, 'location')}>
							<Dropdown.Toggle className="btn-sm" variant="success">Location</Dropdown.Toggle>
							<Dropdown.Menu>
								{isEmpty(this.state.selectedCampaignLocationList) ? (
										<Dropdown.Item disabled>No Location found</Dropdown.Item>
									) : (
										this.state.selectedCampaignLocationList.map((location, index) => (
											<Dropdown.Item key={`location${index}`} disabled>
												{location.location}
											</Dropdown.Item>
										))
								)}
							</Dropdown.Menu>
						</Dropdown>
					);
				}

				let selectedCliAction;
				if(this.state.activeClicampaignId !== campaign.campaignId){
					selectedCliAction = (
						<Dropdown show={false}>
							<Dropdown.Toggle onClick={() => this.getCampaignAdditionalInfo(campaign.campaignId, 'cli')} size="sm" variant="success">CLI</Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item>
									CLI
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					);
				} else {
					selectedCliAction = (
						<Dropdown show={this.state.activeClicampaignId === campaign.campaignId} onToggle={(isOpen) => this.handleDropdownToggle(isOpen, 'cli')}>
							<Dropdown.Toggle className="btn-sm" variant="success">CLI</Dropdown.Toggle>
							<Dropdown.Menu>
								{isEmpty(this.state.selectedCampaignCliList) ? (
									<Dropdown.Item disabled>No CLI found</Dropdown.Item>
								) : (
									this.state.selectedCampaignCliList.map((cli, index) => (
										<Dropdown.Item key={`Cli${index}`} disabled>
											{cli.cli}
										</Dropdown.Item>
									))
								)}
							</Dropdown.Menu>
						</Dropdown>
					);
				}

			return {
				id: index + 1,
				campaignId: campaign.campaignId,
				name: campaign.campaignName,
				campaignName: (campaign.templateId === 0 || campaign.templateId === 1 || campaign.templateId === 2 || campaign.templateId === 7 || campaign.templateId === 8 || campaign.templateId === 9)?<Link key={`${index}-cm`} to={`${this.props.app.urlPrefix}/voice-campaign-info/${campaign.campaignId}`}>{campaign.campaignName}</Link>:campaign.campaignName,
				campaignstatus: status,
				templateId: template,
				cliList: selectedCliAction,
				locationList: selectedLocationAction,
				scheduleTime: campaign.scheduleTime,
				endTime: campaign.endTime,
				numbersUploaded: campaign.numbersUploaded,
				numbersProcessed: campaign.numbersProcessed,
				callsSubmitted: (campaign.campaignStatus === 7)?ccPending:campaign.numbersUploaded - campaign.dndCount - campaign.numbersProcessed,
				callsSubmittedCsv: campaign.numbersUploaded - campaign.dndCount - campaign.numbersProcessed,
				callsConnected: (campaign.campaignStatus === 2)?ccDownload:campaign.callsConnected,
				callsConnectedCsv: campaign.callsConnected,
				pulses: campaign.pulses,
				dndCount: campaign.dndCount,
				dtmfCount: (campaign.campaignStatus === 2)?dtmfDownload:campaign.dtmfCount,
				dtmf1Count: (campaign.campaignStatus === 2)?dtmf1Download:campaign.dtmf1Count,
				dtmf2Count: (campaign.campaignStatus === 2)?dtmf2Download:campaign.dtmf2Count,
				dtmfCountCsv: campaign.dtmfCount,
				dtmf1CountCsv: campaign.dtmf1Count,
				dtmf2CountCsv: campaign.dtmf2Count,
				smsCount: (campaign.campaignStatus === 2)? smsDownload : campaign.smsCount,
				smsCount1: campaign.smsCount,
				retryCount: campaign.retryCount,
				retries: (campaign.retries > 0)?'Yes':'No',
				retries: campaign.retries,
				ttsCount : campaign.ttsCount,
				fullReportStatus: action,
				retry: retry
			};
		}) : []
		const { SearchBar, ClearSearchButton } = Search;
		const { ExportCSVButton } = CSVExport;

		const defaultSorted = [{
			dataField: 'scheduleTime',
			order: 'desc'
		}];

		const paginationOption = {
			custom: true,
			totalSize: products.length,
			sizePerPage: this.state.sizePerPage,
			sizePerPageList: [10, 25, 50, 100],
			onSizePerPageChange: this.handleSizePerPageChange
		};
		const filterTime = (date) => {
			const isPastTime = new Date().getTime() > date.getTime();
			return !isPastTime;
			};

		const locationList = cloneDeep(this.state.locationList);
		let locationDropdown = '';
		if (!isEmpty(locationList) && (!('error' in locationList))) {
			locationDropdown = Array.isArray(locationList) ? locationList.map((obj, index) => {
				return (
				<Form.Check
					key={`location${index}`}
					type="checkbox"
					label={obj.locationName}
					id={`location-${obj.locationId}`}
					value={obj.locationId}
					checked={this.state.location.some(loc => loc.locationId === obj.locationId)}
					onChange={() => this.handleLocationSelect(obj.locationId)}
				/>
				);
			}) : [];
			} else {
			locationDropdown = <p>No location found</p>;
		}

		const cliList = cloneDeep(this.state.cliList);
		let cliDropdown = '';
		if (!isEmpty(cliList) && (!('error' in cliList))) {
			cliDropdown = Array.isArray(cliList) ? cliList.map((obj, index) => {
				return (
				<Form.Check
					key={`cli${index}`}
					type="checkbox"
					label={`${obj.cli} (${obj.dialerName})`}
					id={`cli-${obj.cliId}`}
					value={obj.cliId}
					checked={this.state.selectedCLI.some(selected  => selected.cliId  === obj.cliId)}
					onChange={() => this.handleCLISelect(obj.cliId)}
				/>
				);
			}) : [];
			} else {
				cliDropdown = <p>No CLI found</p>;
		}


		return (
			<Card>
					<Card.Header>Voice Campaign Summary</Card.Header>
					<Card.Body>
						<div className="campaign-list-table-cont">
							{/* <h3>Campaign Summary</h3> */}
							
							{/*{!isEmpty( this.state.campaignlist ) && <PaginationProvider pagination={paginationFactory( paginationOption )}>*/}
							{ this.state.showTable ? <PaginationProvider pagination={paginationFactory(paginationOption)}>
								{({
									paginationProps, paginationTableProps
								}) => (<ToolkitProvider
									keyField="id"
									data={products}
									columns={columns}
									search
									bootstrap4
									exportCSV={{
										fileName: 'campaign_summary.csv'
									}}
								>
									{props => (<div className="">
										Show <SizePerPageDropdownStandalone className="search-dropdown" {...paginationProps} /> items
										<span className="search-cont"><SearchBar {...props.searchProps} /></span>
										<ClearSearchButton className="btn-primary" {...props.searchProps} />
										<ExportCSVButton className="export-csv btn-primary ml-2" {...props.csvProps}>Export to CSV</ExportCSVButton>
										<Button id="refresh" type="button" className="float-right btn btn-default btn-primary ml-2" onClick={() => this.refreshPage()}>Refresh</Button>
										{ (this.state.selectedRows.length >0 ) ? <Button id="refresh" type="button" className="float-right btn btn-default btn-primary ml-2" onClick={() => this.bulkGenerateReport()}>Generate Bulk Report</Button> : <></>}
										<hr/>
										<BootstrapTable bordered={true} hover defaultSorted={defaultSorted} wrapperClasses="table-responsive" classes="w-auto campaignlist-table" {...props.baseProps} {...paginationTableProps} noDataIndication={() => (<div>{'No data available'}</div>)} />
										<PaginationListStandalone {...paginationProps} />
									</div>)}
								</ToolkitProvider>)}
							</PaginationProvider> 
							                    : <div className="loader">
                                                          <Spinner animation="border" variant="primary" />
                                                  </div>}
						</div>
						<Modal size="md" show={this.state.confirmStop} onHide={() => this.confirmStop(false, '')}>
							<Modal.Header closeButton>
								<Modal.Title id="example-modal-sizes-title-sm">
									Stop Campaign
								</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								Are you sure you want to stop this campaign?
							</Modal.Body>
							<Modal.Footer>
								<Button onClick={() => this.confirmStop(false, '')} variant="secondary">CANCEL</Button>
								<Button onClick={this.stopCampaign} variant="primary">YES</Button>
							</Modal.Footer>
						</Modal>

						<Modal size="md" show={this.state.confirmPause} onHide={() => this.confirmPause(false, '')}>
							<Modal.Header closeButton>
								<Modal.Title id="example-modal-sizes-title-sm">
									Pause Campaign
								</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								Are you sure you want to pause this campaign?
							</Modal.Body>
							<Modal.Footer>
								<Button onClick={() => this.confirmPause(false, '')} variant="secondary">CANCEL</Button>
								<Button onClick={this.pauseCampaign} variant="primary">YES</Button>
							</Modal.Footer>
						</Modal>

						<Modal size="md" show={this.state.confirmResume} onHide={() => this.confirmResume(false, '')}>
							<Modal.Header closeButton>
								<Modal.Title id="example-modal-sizes-title-sm">
									Resume Campaign
								</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								Are you sure you want to resume this campaign?
							</Modal.Body>
							<Modal.Footer>
								<Button onClick={() => this.confirmResume(false, '')} variant="secondary">CANCEL</Button>
								<Button onClick={this.resumeCampaign} variant="primary">YES</Button>
							</Modal.Footer>
						</Modal>

						<Modal size="md" show={this.state.confirmRetry} onHide={() => this.confirmRetry(false, '')}>
							<Modal.Header closeButton>
								<Modal.Title id="example-modal-sizes-title-sm">
									Retry Campaign
								</Modal.Title>
							</Modal.Header>
							<Modal.Body>
							    <Row>
							      Are you sure you want to retry this campaign?
							        <Form.Group className="form-group">
									    {Object.keys(this.state.retryOptionStates).length > 0 ? (
							                <>
											    {Object.keys(this.state.retryOptionStates).length > 1 && (		
							                        <Form.Check
							        	              checked={this.state.allSelected}
							        	              onChange={(e) => this.toggleCheckedChange(e, 'All')}
							        	              custom
							        	              label="All"
							        	              id="All"
							        	              type="checkbox"
							                        />
												)}
							                    {Object.keys(this.state.retryOptionStates).  map((optionName) => (
							        	            <Form.Check
							        	        	  key={optionName}
							        	        	  checked={this.state.retryOptionStates[optionName]}
							        	        	  onChange={(e) => this.toggleCheckedChange(e, optionName)}
							        	        	  custom
							        	        	  label={`${optionName} (${this.state.apiRetryOptions.find(option => option.optionName === optionName)?.count || 0})`}
							        	        	  id={optionName}
							        	        	  type="checkbox"
							        	            />
							                    ))}
											</>
										) : (
											    <p>Checking for retry data count</p>
										)}
						            </Form.Group>
						        </Row>
							</Modal.Body>
							<Modal.Footer>
								<Button onClick={() => this.confirmRetry(false, '')} variant="secondary">CANCEL</Button>
								<Button onClick={this.retryCampaign} variant="primary">YES</Button>
							</Modal.Footer>
						</Modal>

						<Modal size="md" show={this.state.confirmEditLocation} onHide={() => this.confirmEditLocation(false, '')}>
							<Modal.Header closeButton>
								<Modal.Title id="example-modal-sizes-title-sm">
									Edit Location
								</Modal.Title>
							</Modal.Header>
							<Modal.Body>
							    <Row>
							      Are you sure you want to edit the location?
								  <Dropdown className="d-inline-block w-100 mt-2">
										<Dropdown.Toggle block variant="light" id="dropdown-basic" className="w-100 text-left" style={{color: '#23282c', backgroundColor: '#d1dbe1', borderColor: '#cad4dc'}}>
										Select Location
										</Dropdown.Toggle>

										<Dropdown.Menu className="pl-2 w-100">
											<Form.Group className="form-group">
												{/* <Form.Label>Select Location</Form.Label> */}
												<Form.Check
													type="checkbox"
													label="Select All"
													id="select-all-locations"
													checked={this.state.selectAllLocations}
													onChange={this.handleSelectAllLocations}
												/>
													{locationDropdown}
											</Form.Group>
										</Dropdown.Menu>
      								</Dropdown>
						        </Row>
							</Modal.Body>
							<Modal.Footer>
								<Button onClick={() => this.confirmEditLocation(false, '')} variant="secondary">CANCEL</Button>
								<Button onClick={this.editLocation} variant="primary">YES</Button>
							</Modal.Footer>
						</Modal>

						<Modal size="md" show={this.state.confirmEditCLI} onHide={() => this.confirmEditCLI(false, '')}>
							<Modal.Header closeButton>
								<Modal.Title>Edit CLI</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<Row>
									Are you sure you want to edit the CLI?
									<Dropdown className="d-inline-block w-100 mt-2">
										<Dropdown.Toggle block variant="light" id="dropdown-basic" className="w-100 text-left" style={{color: '#23282c', backgroundColor: '#d1dbe1', borderColor: '#cad4dc'}}>
											Select CLI
										</Dropdown.Toggle>
										<Dropdown.Menu className="pl-2 w-100">
										<Form.Group className="form-group">
											<Form.Check
												type="checkbox"
												label="Select All"
												id="select-all-clis"
												checked={this.state.selectAllCLIs}
												onChange={this.handleSelectAllCLIs}
											/>
											{cliDropdown}
										</Form.Group>
										</Dropdown.Menu>
									</Dropdown>
								</Row>
							</Modal.Body>
							<Modal.Footer>
								<Button onClick={() => this.confirmEditCLI(false, '')} variant="secondary">CANCEL</Button>
								<Button onClick={this.editCLI} variant="primary">YES</Button>
							</Modal.Footer>
						</Modal>



						{(this.state.reloading === true) && <div className="fetching-data"><img alt="" src="/assets/loading.gif" /></div>}
					</Card.Body>
				</Card>
		);
	}
}


export default ListVoiceCampaign;