import React, { Component } from 'react';
import { Form, Row, Col, Card, Button, Modal } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import { Fetcher } from "../../../Helpers/fetcher.js";
import { Notify } from "../../../Helpers/notification.js";
import { cloneDeep, isEmpty } from 'lodash';
import "./index.css";

class UpdateWebhooks extends Component {
	constructor(props) {
		super(props);
		let sitePath;
		let filePath;
		
		const protocol = window.location.protocol;
		const slashes = protocol.concat("//");
		sitePath = slashes.concat(window.location.hostname);
		this.state = {
			userId: props.app.userId,
			apiPath: props.app.apiPath,
			sitePath: sitePath,
			filePath: filePath,
			webhookUpdated: false,
			mobile: '',
            webhookname : '',
            webhookurl : '',
            smsText :'',
			payload : '',
			requestType: ''
		}

	}

	componentDidMount() {
        const apiPath = this.props.app.apiPath;
		const wId = this.props.selectedWebhookId;
        Fetcher.get(apiPath + '/api/obd/getSMS/webhook/' + wId)
			.then(res => {
				this.setState({
                    webhookurl : res.url,
                    webhookname : res.webhookName,
                    smsText : res.smsText,
					payload : res.payload,
					requestType : res.requestType
				})
				

			})
			.catch(err => { console.log('Error in fetching Webhook data', err) });
	}

	toggleChange = (e, name) => {
		this.setState({
			[name]: !this.state[name],
		});
	}


	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}

	setMessageShow = (status) => {
		this.setState({
		});
	}

	handleAddWebhooks = (event) => {
		event.preventDefault();
		const data = {
            "id":this.props.selectedWebhookId,
			"webhookName": this.state.webhookname,
			"url": this.state.webhookurl,
            "userId" : Number(this.state.userId),
            "smsText" : this.state.smsText,
            "payload" : this.state.payload,
			"requestType" : this.state.requestType
		};

		return Fetcher.post(this.props.app.apiPath + '/api/obd/sms/webhooks/edit', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
					
				let webhookUpdated = false;
				if (res.message === 'Parameters Incorrect') {
					Notify.notify(res.message);
				} else {
					Notify.notify(res.message);
					webhookUpdated = true;
				}
				this.setState({
					webhookUpdated: webhookUpdated
				})
			})
			.catch(err => {
				console.log('Error in updating webhook', err);
				Notify.notify('Error in updating webhook');
			});
	}

	addZero = (i) => {
		if (i < 10) {
			i = "0" + i;
		}
		return i;
	}

	render() {
		if (this.state.webhookUpdated === true) {
			return <Redirect to='/sms-webhook-list' />
		}
		let submitButtonlabel = 'Edit Webhook';

		return (

			<Form className="add-campaign-form" onSubmit={(e) => this.handleAddWebhooks(e)}>
				<Row>
					<Col sm={12}>
						<Card>
							<Card.Header>Edit Webhook</Card.Header>
							<Card.Body>
								<Row>
									<Col sm={3}>
										<Form.Group className="form-group required control-label">
											<Form.Control required name="webhookname" type="text" placeholder="Webhook Name" onChange={e => this.setvalue(e)} value={this.state.webhookname} autoComplete="off" />
										</Form.Group>
									</Col>
									<Col sm={3}>
										<Form.Group className="form-group required control-label">
										<Form.Select className="dd" required name="requestType" onChange={e => this.setvalue(e)} as="select">
											<option value={(this.state.requestType)}>{this.state.requestType}</option>
											<option value="GET">GET</option>
											<option value="WHATSAPP">WHATSAPP</option>
										</Form.Select>
										</Form.Group>
									</Col>
								</Row>
								<Col sm={6}>
											<Form.Group className="form-group"  >
											<Form.Label>Note: Please paste the SMS API here and replace the Mobile No. with &#123;PHONE&#125; </Form.Label>
											<Row className="align-items-center">
												<Col>
													<Form.Control required name="webhookurl" value={this.state.webhookurl} onChange={e => this.setvalue( e )} as="textarea" rows="2" />
												</Col>
										    </Row>
											</Form.Group>
								</Col>

								 {this.state.requestType === "" ? 
                                <Col sm={6}>
                                        <Form.Group className="form-group"  >
                                        <Form.Label>Note: Please Paste the SMS text here </Form.Label>
                                        <Row className="align-items-center">
                                            <Col>
                                                <Form.Control required name="smsText" value={this.state.smsText} onChange={e => this.setvalue( e )} as="textarea" rows="2" />
                                            </Col>
                                        </Row>
                                        </Form.Group>
                                </Col>
								: <></>
	                            }


                                {this.state.requestType === "GET" ? 
                                <Col sm={6}>
                                        <Form.Group className="form-group"  >
                                        <Form.Label>Note: Please Paste the SMS text here </Form.Label>
                                        <Row className="align-items-center">
                                            <Col>
                                                <Form.Control required name="smsText" value={this.state.smsText} onChange={e => this.setvalue( e )} as="textarea" rows="2" />
                                            </Col>
                                        </Row>
                                        </Form.Group>
                                </Col>
								: <></>
	                            }

								{this.state.requestType==="WHATSAPP" ?
									<Col sm={6}>
										<Form.Group className="form-group"  >
										<Form.Label>Note: Please Paste the Payload here </Form.Label>
										<Row className="align-items-center">
											<Col>
												<Form.Control required name="payload" value={this.state.payload} onChange={e => this.setvalue( e )} as="textarea" rows="2" />
											</Col>
										</Row>
										</Form.Group>
									</Col>
									: <></>
								}
								<Button variant="primary" type="submit">{submitButtonlabel}</Button>
							</Card.Body>
						</Card>

					</Col>
				</Row>
			</Form>
		);
	}
}

export default UpdateWebhooks;