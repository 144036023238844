import React, { Component } from 'react';
import { Form, Row, Col, Card, Button } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import { Fetcher } from "../../../Helpers/fetcher.js";
import { Notify } from "../../../Helpers/notification.js";
import "./index.css";

class AddWebhooks extends Component {
	constructor(props) {
		super(props);
		let sitePath;
		let filePath;
		
		const protocol = window.location.protocol;
		const slashes = protocol.concat("//");
		sitePath = slashes.concat(window.location.hostname);
		this.state = {
			userId: props.app.userId,
			apiPath: props.app.apiPath,
			sitePath: sitePath,
			filePath: filePath,
			webhookAdded: false,
			mobile: '',
            webhookname : '',
            webhookurl : '',
            smsText : '',
			payload : '',
			requestType : ''
		}

	}

	toggleChange = (e, name) => {
		this.setState({
			[name]: !this.state[name],
		});
	}


	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}

	setMessageShow = (status) => {
		this.setState({
		});
	}

	handleAddWebhooks = (event) => {
		event.preventDefault();
		const data = {
			"webhookName": this.state.webhookname,
			"url": this.state.webhookurl,
            "userId" : Number(this.state.userId),
            "smsText" : this.state.smsText,
			"payload" : this.state.payload,
			"requestType" : this.state.requestType
		};

		return Fetcher.post(this.props.app.apiPath + '/api/obd/add/sms/webhooks', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
					
				let webhookAdded = false;
				if (res.message === 'Parameters Incorrect') {
					Notify.notify(res.message);
				} else {
					Notify.notify(res.message);
					webhookAdded = true;
				}
				this.setState({
					webhookAdded: webhookAdded
				})
			})
			.catch(err => {
				console.log('Error in Adding webhooks', err);
				Notify.notify('Error in Adding webhooks');
			});
	}

	addZero = (i) => {
		if (i < 10) {
			i = "0" + i;
		}
		return i;
	}

	render() {
		if (this.state.webhookAdded === true) {
			return <Redirect to='/sms-webhook-list' />
		}
		let submitButtonlabel = 'Add Sms Webhook';

		return (

			<Form className="add-campaign-form" onSubmit={(e) => this.handleAddWebhooks(e)}>
				<Row>
					<Col sm={12}>
						<Card>
							<Card.Header>Add Sms Webhook</Card.Header>
							<Card.Body>
								<Row>
									<Col sm={3}>
										<Form.Group className="form-group required control-label">
											<Form.Control required name="webhookname" type="text" placeholder="Webhook Name" onChange={e => this.setvalue(e)} value={this.state.webhookname} autoComplete="off" />
										</Form.Group>
									</Col>
									<Col sm={3}>
										<Form.Group className="form-group required control-label">
										<Form.Select className="dd" required name="requestType" onChange={e => this.setvalue(e)} as="select">
											<option value="">Select Request Type</option>
											<option value="GET">GET</option>
											<option value="WHATSAPP">WHATSAPP</option>
										</Form.Select>
										</Form.Group>
									</Col>
								</Row>

								<Row> 
									<Col sm={6}>
											<Form.Group className="form-group"  >
											<Form.Label>Note: Please paste the SMS API URL here </Form.Label>
											<Row className="align-items-center">
												<Col>
													<Form.Control required name="webhookurl" value={this.state.webhookurl} onChange={e => this.setvalue( e )} as="textarea" rows="2" />
												</Col>
										    </Row>
											</Form.Group>
									</Col>
                                   {this.state.requestType==="GET" ?
                                    <Col sm={6}>
											<Form.Group className="form-group"  >
											<Form.Label>Note: Please Paste the SMS text here </Form.Label>
											<Row className="align-items-center">
												<Col>
													<Form.Control required name="smsText" value={this.state.smsText} onChange={e => this.setvalue( e )} as="textarea" rows="2" />
												</Col>
										    </Row>
											</Form.Group>
									</Col>
									: <></>}
								{this.state.requestType==="WHATSAPP" ?
                                    <Col sm={6}>
											<Form.Group className="form-group"  >
											<Form.Label>Note: Please Paste the Payload here </Form.Label>
											<Row className="align-items-center">
												<Col>
													<Form.Control required name="payload" value={this.state.payload} onChange={e => this.setvalue( e )} as="textarea" rows="2" />
												</Col>
										    </Row>
											</Form.Group>
									</Col>
									: <></>
								}

                            </Row>  
                                    
								
								<Button variant="primary" type="submit">{submitButtonlabel}</Button>
							</Card.Body>
						</Card>

					</Col>
				</Row>
			</Form>
		);
	}
}

export default AddWebhooks;